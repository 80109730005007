import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EditProduct = ({ product, onUpdate, onClose }) => {
  const [formData, setFormData] = useState({
    item_name: product.item_name || '',
    ean: product.ean || '',
    image_links: product.image_links || '',
  });
  useEffect(() => {
    if (product) {
      setFormData({
        sku_code: product.sku_code, // Access 'sku_code' field properly
        item_name: product.item_name,
        ean: product.ean,
        image_links: product.image_links,
        updated_at: product.updated_at
      });
    }
  }, [product]);
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log('Submitting data:', formData);
      console.log('API URL:', `${API_BASE_URL}/sku/update/${formData.sku_code}`);
      
      const response = await axios.put(`${API_BASE_URL}/sku/update/${formData.sku_code}`, {
        item_name: formData.item_name,
        ean: formData.ean,
        image_links: formData.image_links
      });
      
      console.log('Update response:', response);
      onUpdate(response.data);
      toast.success('Product updated successfully');
      onClose();
    
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      console.error('Failed to update product:', error.response ? error.response.data : error.message);
      toast.error('Failed to update product');
    }
  };
  
  

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div>
          <label className='pb-2' htmlFor="sku_code">SKU Code:</label>
          <input type="text" className='form-control mb-3' name="sku_code" value={formData.sku_code} readOnly disabled />
        </div>
        <div>
          <label className='pb-2' htmlFor="item_name">Product Name:</label>
          <input type="text" className='form-control mb-3' name="item_name" value={formData.item_name} onChange={handleChange} />
        </div>
        <div>
          <label className='pb-2' htmlFor="ean">EAN:</label>
          <input type="text" className='form-control mb-3' name="ean" value={formData.ean} onChange={handleChange} />
        </div>
        <div>
          <label className='pb-2' htmlFor="image_links">Image Links:</label>
          <input type="text" className='form-control mb-3' name="image_links" value={formData.image_links} onChange={handleChange} />
        </div>
        <button className='btn btn-primary1 w-100' type="submit">Save</button>
      </form>
      <ToastContainer position="top-right" autoClose={3000} />
    </div>
  );
};

export default EditProduct;
