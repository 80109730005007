import React, { useState, useEffect } from 'react';
import BarcodeData from './components/BarcodesData';
import { Offcanvas, OffcanvasBody, OffcanvasHeader, Button, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Sidebar from './components/sidebar';
import FileUpload from './components/FileUpload';
import { FaFileExport, FaTrashAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MrpData = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedOption, setSelectedOption] = useState('mrp');
    const [tableOption, setTableOption] = useState('mrp');
    const [data, setData] = useState([]);
    const [apiEndpoint, setApiEndpoint] = useState(`${process.env.REACT_APP_API_BASE_URL}/mrp/upload-chunk`);
    const [currentEndpoint, setCurrentEndpoint] = useState(apiEndpoint);
    const [modal, setModal] = useState(false);

    const toggleModal = () => {
        console.log('Toggling modal:', !modal);
        setModal(!modal);
    };

    useEffect(() => {
        const newApiEndpoint = `${process.env.REACT_APP_API_BASE_URL}/${selectedOption}/upload-chunk`;
        setApiEndpoint(newApiEndpoint);
    }, [selectedOption]);

    useEffect(() => {
        setCurrentEndpoint(apiEndpoint);
    }, [apiEndpoint]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/${tableOption}/get-data`);
                const result = await response.json();
                setData(result);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [tableOption]);

    const toggleCanvas = () => {
        setIsOpen(!isOpen);
    };

    const convertToCSV = (data) => {
        if (data.length === 0) return '';
        const header = Object.keys(data[0]).filter(key => key !== 'barcode').join(',');
        const rows = data.map(item => 
            Object.keys(item)
                .filter(key => key !== 'barcode')
                .map(key => `"${String(item[key]).replace(/"/g, '""')}"`) // Enclose in quotes and escape quotes
                .join(',')
        ).join('\n');
        return [header, rows].join('\n');
    };

    const downloadCSV = (csv, filename) => {
        if (!csv) return;
        const csvFile = new Blob([csv], { type: 'text/csv' });
        const downloadLink = document.createElement('a');
        downloadLink.download = filename;
        downloadLink.href = window.URL.createObjectURL(csvFile);
        downloadLink.style.display = 'none';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    const handleSelectItem = (itemId) => {
        const updatedSelectedItems = selectedItems.includes(itemId)
            ? selectedItems.filter(id => id !== itemId)
            : [...selectedItems, itemId];
        setSelectedItems(updatedSelectedItems);
        // Notify parent component (if needed)
    };

    const handleSelectAll = (filteredData) => {
        if (selectedItems.length === filteredData.length) {
            setSelectedItems([]);
            // Notify parent component (if needed)
        } else {
            const allItemIds = filteredData.map(item => item.fsn || item.asin);
            setSelectedItems(allItemIds);
            // Notify parent component (if needed)
        }
    };

    const handleExportSelected = () => {
        if (selectedItems.length === 0) {
            alert('No items selected for export.');
            return;
        }
        const selectedData = data.filter(item => selectedItems.includes(item.fsn || item.asin));
        const csv = convertToCSV(selectedData);
        downloadCSV(csv, 'skretail_mrpdata_export.csv');
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleDropdownChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleDropdownChange1 = (event) => {
        setTableOption(event.target.value);
    };

    const handleDeleteSelected = () => {
        console.log('Delete button clicked');
        toggleModal();
    };
    const handleBulkDelete = async () => {
        console.log('Handle Bulk Delete called');
        if (selectedItems.length === 0) {
            console.log('No items selected for deletion.');
            return; // Exit if no items are selected
        }
    
        // Verify the structure of selectedItems
        console.log('Selected items:', selectedItems);
    
        // Construct the payload with only fsn values
        const itemsToDelete = selectedItems.map(fsn => ({ fsn }));
        
        // Log itemsToDelete for verification
        console.log('Items to delete:', itemsToDelete);
    
        if (itemsToDelete.length === 0) {
            console.log('No valid items to delete.');
            return;
        }
    
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/${tableOption}/bulk-delete`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ items: itemsToDelete })
            });
    
            const resultText = await response.text();
            console.log('Response status:', response.status);
            console.log('Response body:', resultText);
    
            if (!response.ok) {
                throw new Error(`Failed to delete items: ${resultText}`);
            }
    
            let result;
            try {
                result = JSON.parse(resultText);
            } catch (error) {
                result = { message: resultText };
            }
    
            // Close the modal
            toggleModal();
    
            // Show toast message after closing the modal
            setTimeout(() => {
                toast.success(result.message || 'Items deleted successfully!');
            }, 100); // Small delay to ensure modal has closed
    
            // Clear selected items
            setSelectedItems([]);
    
            // Refresh data if needed
            setTimeout(() => {
                window.location.reload();
            }, 3000);
    
        } catch (error) {
            console.error('Error deleting items:', error.message);
            toast.error(`Failed to delete items: ${error.message}`);
            // Ensure modal is closed even if there's an error
            toggleModal();
            setSelectedItems([]);
        }
    };
        

    return (
        <div className='d-flex w-100 overflow-hidden'>
            <div className='asidebar'>
                <Sidebar />
            </div>
            <div className='main-content'>
                <div className='d-flex align-items-center justify-content-between mb-4'>
                    <h2>MRP Label Data</h2>
                    <div className='d-flex align-items-center justify-content-end'>
                        <div className="me-3">
                            <input
                                type="text"
                                className="form-control w-300px rounded-50 ps-3"
                                style={{ height: '50px' }}
                                placeholder="Search..."
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                        </div>
                        <div className='me-3 w-120px'>
                            <Input className='w-120' type="select" value={tableOption} onChange={handleDropdownChange1}>
                                <option value="mrp">Flipkart - ZAP</option>
                                <option value="gn">Flipkart - GN</option>
                                <option value="appario">ClickTech</option>
                                <option value="coco">Cocoblu</option>
                            </Input>
                        </div>
                        <Button color="secondary" className='px-3 rounded-50 py-2 me-3 mw-60px' onClick={handleExportSelected}>
                            <FaFileExport />
                        </Button>
                        <Button color="danger" className='px-3 rounded-50 py-2 me-3 mw-60px' onClick={handleDeleteSelected}>
                        <FaTrashAlt />
                        </Button>
                        <Button color="primary" className='px-5' onClick={toggleCanvas}>
                            Upload MRP Data
                        </Button>
                    </div>
                </div>
                {selectedItems.length > 0 && (
                    <div className='mb-4'>
                        <p>Selected Items: <b>{selectedItems.length}</b></p>
                    </div>
                )}

                <BarcodeData
                    searchQuery={searchQuery}
                    onExportSelected={handleExportSelected}
                    setData={setData}
                    selectedItems={selectedItems}
                    onSelectionChange={setSelectedItems}
                    dataapiEndpoints={[
                        `${process.env.REACT_APP_API_BASE_URL}/${tableOption}/get-data`
                    ]}
                    onItemSelect={handleSelectItem}
                    onSelectAll={handleSelectAll}
                />

                <Offcanvas isOpen={isOpen} toggle={toggleCanvas} className="custom-canvas-width" direction='end'>
                    <OffcanvasHeader className='mb-4 mx-4 mt-2 border-bottom' toggle={toggleCanvas}>
                        <h3 className='font-weight-bold'>Upload MRP Data</h3>
                    </OffcanvasHeader>
                    <OffcanvasBody>
                        <div className='mb-3'>
                            <Input type="select" value={selectedOption} onChange={handleDropdownChange}>
                                <option value="mrp">Flipkart - ZAP</option>
                                <option value="gn">Flipkart - GN</option>
                                <option value="appario">ClickTech</option>
                                <option value="coco">Cocoblu</option>
                            </Input>
                        </div>
                        <FileUpload apiEndpoint={currentEndpoint} key={currentEndpoint} />
                    </OffcanvasBody>
                </Offcanvas>
                <Modal isOpen={modal} toggle={toggleModal}>
                    <ModalHeader toggle={toggleModal}>Confirm Delete</ModalHeader>
                    <ModalBody>
                        Are you sure you want to delete the selected items?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={handleBulkDelete}>Delete</Button>{' '}
                        <Button color="secondary" onClick={toggleModal}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
            <ToastContainer />
        </div>
    );
};

export default MrpData;
